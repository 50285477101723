import { Formik, Form, FormikHelpers } from 'formik';
import { atom, useAtom } from "jotai";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Box from "../components/Box/Box";
import { formDataAtom } from "../App";
import axios from "axios";
import Progressbar from "../components/Progressbar/Progressbar";
import { FOR_PATIENT } from "./MedicalTravel";
import { BY_BOTH, BY_PRIVATE, BY_PUBLIC } from "./TravelDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useRef, useState } from "react";
import Checkbox from "../components/Form/Checkbox";
import BackButton from "../components/Buttons/BackButton";
import ErrorList from "../components/Form/ErrorList";
import { MedicalTravelModel, MedicalVisit } from "../types/FormTypes";
import { handleSubmitClick } from "../components/Form/FormUtils";
import Spinner from "../components/Spinner/Spinner";
import useFocusOnLoad from "../hooks/useFocusOnLoad";

interface SummaryModel {
  gdprAccept: boolean;
}
interface Issue {
  id: string;
}
enum VisitField {
  certificate,
  wayOfTravel,
  dateTo,
  dateFrom,
  travelDescription,
  receipts,
  otherFiles,
  comments,
}

export const issueAtom = atom<Issue>({
  id: "",
});

export default function Summary() {
  const [formData, setFormData] = useAtom(formDataAtom);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const h2Ref = useFocusOnLoad<HTMLHeadingElement>();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setIssueId] = useAtom(issueAtom);
  const formRef = useRef<HTMLFormElement>(null);

  function GetEditButtonForNavigation(path: string, text?: string) {
    return (
      <button
        type="button"
        className="btn stripped icon-link p-0 justify-self-end change-link"
        onClick={() => navigate(path)}
      >
        <FontAwesomeIcon
          className="text-xl mr-2 relative top-0.5 text-r"
          icon={solid("edit")}
        />
        {text ?? "Gå tillbaka och ändra"}
      </button>
    );
  }
  function GetDeleteButtonForMedicalVisit(index: number) {
    return (
      <div className="summary-remove">
        <button
          type="button"
          className="btn stripped icon-link p-0 justify-self-end"
          onClick={() => {
            if (index < formData.medicalVisits.length) {
              formData.medicalVisits.splice(index, 1);
              setFormData({
                ...formData,
                medicalVisits: [...formData.medicalVisits],
              });
            }
          }}
        >
          <FontAwesomeIcon
            className="text-xl mr-2 relative top0.5"
            icon={solid("trash-can")}
          />
          Ta bort vårdbesök {index + 1}
        </button>
      </div>
    );
  }
  const handleSubmit = (values: SummaryModel, actions: FormikHelpers<SummaryModel>) => {
    // Perform your submit actions here

    const updatedFormData = {
      ...formData,
      patientssn: formData.patientssn.replace(/-/g, ''),
      caregiverssn: formData.caregiverssn.replace(/-/g, ''),
    };

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    setIsFormSubmitted(true);
    axios
      .post(  
        `${apiBaseUrl?.replace(/\/$/, '') ?? ''}/crm/medicalTravel`,
        updatedFormData,
        {
          headers: {
            "referrer-policy": "strict-origin",
          },
        }
      )
      .then((result) => {
        setIssueId({ id: result.data });
        setFormData({
          compensationfor: "",
          patientssn: "",
          patientfirstname: "",
          patientlastname: "",
          patientemail: "",
          patientphone: "",
          caregiverssn: "",
          caregiverfirstname: "",
          caregiverlastname: "",
          caregiveremail: "",
          caregiverphone: "",
          medicalVisits: [],
        });
        navigate("/tack-sida");
      })
      .catch((error) => {
        navigate("../ett-fel-intraffade");
      });
  };
  return (
    <div>
      <Formik<SummaryModel>
        onSubmit={handleSubmit}


       
        initialValues={{
          gdprAccept: false,
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object().shape({
          gdprAccept: Yup.bool().test(
            "checkbox-selected",
            "Du måste godkänna hanteringen av dina personuppgifter.",
            (value) => {
              return value === true;
            }
          ),
          ...formValidation(formData),
        })}
        enableReinitialize
      >
        {({ errors, values }) => (
          <>
            <div className="flex justify-between items-center">
              <div className="flex justify-center h-15 md:h-auto mb-1">
                <div
                  className={`w-full text-left md:hidden ${isFormSubmitted === true ? "hide" : ""
                    }`}
                >
                  <BackButton />
                </div>
              </div>

              <div className="w-40 xs:w-52 md:w-full max-w-xs md:mb-4">
                <Progressbar percentage={"100"} />
              </div>
            </div>
            <Form ref={formRef}>
              <Box fullwidth>
                {isFormSubmitted && <Spinner label="Skickar ..." />}

                <div
                  className={`inner-summary ${isFormSubmitted === true ? "hide" : ""
                    }`}
                >
                  <h2 ref={h2Ref} tabIndex={-1}>
                    Granska och skicka in ansökan
                  </h2>

                  <ErrorList errors={errors} />

                  <p>
                    Granska dina uppgifter innan du skickar in ansökan. Vill du
                    ändra något klickar du på "Ändra". Då kommer du till sidan i
                    formuläret där du kan ändra. Klicka sedan på "Gå vidare" för
                    att göra klart ansökan.
                  </p>

                  <div className="summary-section patient-summary">
                    <div className="flex md:justify-between justify-start flex-col md:flex-row md:mb-5">
                      <h3 className="summary-section-heading">Patient</h3>
                      <div className="summary-edit md:text-right text-left mb-8">
                        {GetEditButtonForNavigation("../")}
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-4 content-start">
                      <div className="summary-data-label">
                        Patientens personnummer
                      </div>
                      <div className="summary-data">{formData.patientssn}</div>
                      <div
                        className={`summary-data-label ${!formData.patientemail ? "last" : ""
                          }`}
                      >
                        Patientens namn
                      </div>
                      <div
                        className={`summary-data ${!formData.patientemail ? "last" : ""
                          }`}
                      >
                        {formData.patientfirstname +
                          " " +
                          formData.patientlastname}
                      </div>
                      {formData.patientemail && (
                        <>
                          <div className="summary-data-label">
                            Patientens epost
                          </div>
                          <div className="summary-data break-all">
                            {formData.patientemail}
                          </div>
                        </>
                      )}

                      {formData.patientphone && (
                        <>
                          <div className="summary-data-label last">
                            Patientens mobilnummer
                          </div>
                          <div className="summary-data last break-all">
                            {formData.patientphone}
                          </div>
                        </>
                      )}
                    </div>

                    {HasCareGiverData(formData) && (
                      <>
                        <div className="flex md:justify-between justify-start flex-col md:flex-row mt-6 md:mt-12 mb-2 md:mb-10">
                          <h3 className="summary-section-heading">
                            Vårdnadshavare
                          </h3>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-4 content-start">
                          <div className="summary-data-label">
                            Vårdnadshavarens personnummer
                          </div>

                          <div className="summary-data">
                            {formData.caregiverssn}
                          </div>
                          <div className="summary-data-label">
                            Vårdnadshavarens namn
                          </div>
                          <div className="summary-data">
                            {formData.caregiverfirstname +
                              " " +
                              formData.caregiverlastname}
                          </div>
                          <div className="summary-data-label">
                            Vårdnadshavarens epost
                          </div>
                          <div className="summary-data break-all">
                            {formData.caregiveremail}
                          </div>
                          <div className="summary-data-label last">
                            Vårdnadshavarens mobilnummer
                          </div>
                          <div className="summary-data last break-all">
                            {formData.caregiverphone}
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  {formData.medicalVisits.map((visit, idx) => {
                    return (
                      <div
                        className={`summary-section visit${idx}-summary`}
                        key={idx}
                      >
                        <div className="flex md:justify-between justify-start flex-col md:flex-row md:mb-5">
                          <h3 className="summary-section-heading">
                            Vårdbesök {idx + 1}
                          </h3>
                          <div className="summary-edit md:text-right text-left mb-8">
                            {GetEditButtonForNavigation(
                              `../reseuppgifter/${idx + 1}/intyg`
                            )}
                          </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-4 content-start">
                          <div
                            className={GetLabelClass(
                              visit,
                              VisitField.certificate
                            )}
                          >
                            Bifogat intyg
                          </div>
                          <div
                            className={GetDataClass(
                              visit,
                              VisitField.certificate
                            )}
                          >
                            {visit.certificate.map(
                              (doc) => doc.file.name + " "
                            )}
                          </div>
                          <div
                            className={GetLabelClass(
                              visit,
                              VisitField.wayOfTravel
                            )}
                          >
                            Hur reste du till/från kliniken?
                          </div>
                          <div
                            className={GetDataClass(
                              visit,
                              VisitField.wayOfTravel
                            )}
                          >
                            {GetWayOfTravelReadable(visit.wayOfTravel)}
                          </div>
                          {visit.dateTo && (
                            <>
                              <div
                                className={GetLabelClass(
                                  visit,
                                  VisitField.dateTo
                                )}
                              >
                                Datum till vårdenhet
                              </div>
                              <div
                                className={GetDataClass(
                                  visit,
                                  VisitField.dateTo
                                )}
                              >
                                {visit.dateTo}
                              </div>
                            </>
                          )}
                          {visit.dateFrom && (
                            <>
                              <div
                                className={GetLabelClass(
                                  visit,
                                  VisitField.dateFrom
                                )}
                              >
                                Datum från vårdenhet
                              </div>
                              <div
                                className={GetDataClass(
                                  visit,
                                  VisitField.dateFrom
                                )}
                              >
                                {visit.dateFrom}
                              </div>
                            </>
                          )}
                          {visit.receipts.length > 0 && (
                            <>
                              <div
                                className={GetLabelClass(
                                  visit,
                                  VisitField.receipts
                                )}
                              >
                                Bifogade biljetter
                              </div>
                              <div
                                className={GetDataClass(
                                  visit,
                                  VisitField.receipts
                                )}
                              >
                                {visit.receipts.map(
                                  (doc) => doc.file.name + " "
                                )}
                              </div>
                            </>
                          )}
                          {visit.travelDescription && (
                            <>
                              <div
                                className={GetLabelClass(
                                  visit,
                                  VisitField.travelDescription
                                )}
                              >
                                Berätta om sträckan som du åkte med bil
                              </div>
                              <div
                                className={GetDataClass(
                                  visit,
                                  VisitField.travelDescription
                                )}
                              >
                                {visit.travelDescription}
                              </div>
                            </>
                          )}
                          {visit.otherFiles.length > 0 && (
                            <>
                              <div
                                className={GetLabelClass(
                                  visit,
                                  VisitField.otherFiles
                                )}
                              >
                                Övriga dokument
                              </div>
                              <div
                                className={GetDataClass(
                                  visit,
                                  VisitField.otherFiles
                                )}
                              >
                                {visit.otherFiles.map(
                                  (doc) => doc.file.name + " "
                                )}
                              </div>
                            </>
                          )}
                          {visit.comments && (
                            <>
                              <div
                                className={GetLabelClass(
                                  visit,
                                  VisitField.comments
                                )}
                              >
                                Övriga kommentarer
                              </div>
                              <div
                                className={GetDataClass(
                                  visit,
                                  VisitField.comments
                                )}
                              >
                                {visit.comments}
                              </div>
                            </>
                          )}
                          {formData.medicalVisits.length > 1 &&
                            GetDeleteButtonForMedicalVisit(idx)}
                        </div>
                      </div>
                    );
                  })}
                  <div className="mt-6 mb-6 md:mb-8">
                    <Checkbox name="gdprAccept">
                      Jag godkänner att Östgötatrafiken sparar och hanterar mina
                      personuppgifter enligt{" "}
                      <Link
                        target="_blank"
                        className="text-red underline hover:no-underline"
                        to="https://www.ostgotatrafiken.se/info/integritetspolicy-for-ostgotatrafikens-kunder/"
                      >
                        relevant dataskyddslagstiftning.
                        <FontAwesomeIcon
                          icon={solid("external-link")}
                          className="ml-2"
                        />
                      </Link>
                    </Checkbox>
                  </div>
                  <div className="w-full text-right mb-4">
                    <button
                      onClick={handleSubmitClick(formRef)}
                      className="btn primary w-full"
                      type="submit"
                    >
                      Skicka in ansökan
                    </button>
                  </div>
                </div>
              </Box>

              <div
                className={`flex justify-centery ${isFormSubmitted === true ? "hide" : ""
                  }`}
              >
                <div className="w-full text-left flex items-center">
                  <BackButton />
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}

function GetWayOfTravelReadable(wayOfTravel: string): string {
  return wayOfTravel === BY_PRIVATE
    ? "Privatbil"
    : wayOfTravel === BY_PUBLIC
      ? "Kollektivtrafik"
      : "Privatbil och kollektivtrafik";
}

function HasCareGiverData(data: MedicalTravelModel): boolean {
  return (
    (data.caregiverssn ||
      data.caregiverfirstname ||
      data.caregiverlastname ||
      data.caregiveremail) !== ""
  );
}
function formValidation(data: MedicalTravelModel): any {
  let valObj = {
    patient: Yup.bool().test(
      "form-filled-out",
      "Det saknas obligatoriska uppgifter för Patient.",
      () => {
        return isPatientFilledOut(data);
      }
    ),
    caregiver: Yup.bool().test(
      "form-filled-out",
      "Det saknas obligatoriska uppgifter för Vårdnadshavare.",
      () => {
        return (
          data.compensationfor !== FOR_PATIENT || isCaregiverFilledOut(data)
        );
      }
    ),
  } as any;
  data.medicalVisits.forEach((visit, index) => {
    valObj[`visit${index}`] = Yup.bool().test(
      "form-filled-out",
      `Det saknas obligatoriska uppgifter för Vårdbesök ${index + 1}.`,
      () => {
        return isVisitFilledOut(visit);
      }
    );
  });
  return valObj;
}
function isPatientFilledOut(data: MedicalTravelModel): boolean {
  return (
    (data.patientssn && data.patientfirstname && data.patientlastname) !== "" &&
    (data.compensationfor === FOR_PATIENT || data.patientemail !== "")
  );
}
function isCaregiverFilledOut(data: MedicalTravelModel): boolean {
  return (
    (data.caregiverssn &&
      data.caregiverfirstname &&
      data.caregiverlastname &&
      data.caregiveremail) !== ""
  );
}
function isVisitFilledOut(visit: MedicalVisit): boolean {
  return (
    visit.certificate.length === 1 &&
    (visit.wayOfTravel === BY_PUBLIC ||
      (visit.dateFrom && visit.dateTo) !== "") &&
    (visit.wayOfTravel === BY_PRIVATE || visit.receipts.length > 0) &&
    (visit.wayOfTravel !== BY_BOTH || visit.travelDescription !== "")
  );
}
function IsLastData(data: MedicalVisit, field: VisitField): boolean {
  if (field === VisitField.comments) {
    return true;
  }
  if (field === VisitField.otherFiles) {
    return data.comments.length === 0;
  }
  if (field === VisitField.travelDescription) {
    return data.otherFiles.length + data.comments.length === 0;
  }
  if (field === VisitField.receipts) {
    return (
      data.travelDescription.length +
      data.otherFiles.length +
      data.comments.length +
      data.dateFrom.length === 
      0
    );
  }
  return false;
}
function GetLabelClass(visit: MedicalVisit, field: VisitField): string {
  const label = "summary-data-label";
  if (IsLastData(visit, field)) return label + " last";
  return label;
}
function GetDataClass(visit: MedicalVisit, field: VisitField): string {
  const label = "summary-data";
  if (IsLastData(visit, field)) return label + " last";
  return label;
}
